.chatbox-container {
    position: fixed;
    bottom: 0;
    right: 30%;
    max-width:400px;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }


  .chatbox .card-header {
      height:80px;
      border-radius:0px;
  }

.chatbox {
    width: 100%;
    max-width:450px;
}

.chatbox-body {
    max-height: 500px;
    overflow-y: auto;
    min-height:500px;
    background:#EFF1F4;
}

.chat-avatar {
    width: 50px;
    height:50px;
    border-radius: 50%;
    margin-right: 10px;
}

.message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.alert {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.alert p {
    margin: 0;
}

.text-end .alert {
    align-items: flex-end;
}

.card-header .btn {
    padding: 0 10px;
    font-size: 16px;
}

.cursor-point {
    cursor: pointer;
}