/* Root Variables */
:root {
    /* Primary colors */
    --primary-color: #7749F8;
    --secondary-color: #6c757d;

    /* Background colors */
    --body-bg-color: #F4F2FC;
    --footer-bg-color: #f8f9fa;

    /* Text colors */
    --text-color: #212529;
    --heading-color: #212529;
    --link-color: #7749F8;

    /* Button colors */
    --btn-primary-color: #7749F8;
    --btn-secondary-color: #16B296;
    --close-button: #B9A4F3;

    /* Border colors */
    --border-color: #dee2e6;
    --border-radius: 0.25rem;
    --linecolor: #D873CE;

    /* Linear colors */
    --body-bg-1: #F4F2FC;
    --body-bg-2: #ffffff;
  }

  .align-drp {
    margin-bottom: 0;
    min-width: 120px;
    text-align:right;
  }

  /* Navbar and Footer */

  .footer {
    background-color: var(--footer-bg-color);
  }

  /* linear gradient bg */
  .vertical-gradient {
    background: linear-gradient(to bottom, var(--body-bg-1), var(--body-bg-2));
  }


  /* Text Colors */

  .primary-text{
    color: var( --link-color);
  }

  .text-secondary {
    color: var(--secondary-color);
  }

  /*Home Page Custom Background Colors */
  .bg-skyblue {
    background-color: var(--body-bg-color) !important;
  }

  .bg-lipink {
    background-color: #F9EBDF !important;
  }

  .bg-liyellow {
    background-color: #F9D8D6 !important;
  }

  .bg-ligree {
    background-color: #E7D3D3 !important;
  }

  .bg-ixprimary {
    background-color: var(--primary-color) !important;
  }

  .content {
    flex: 1;
  }
