.bm-menu {
  background: #fff;
  font-size: 1.15em;
  box-shadow: -4px 0px 18px 0px rgba(0, 0, 0, 0.08);
}

body .bm-cross-button {
  height: 24px;
  width: 24px;
  top: 25px !important;
  right: 25px !important;
}

.bm-menu-wrap {
  top: 0;
  max-width: 400px;
  width: 100% !important;
}
.bm-overlay {
  top: 0;
  background: rgba(217, 217, 217, 0.33) !important;
  z-index: 999;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
}

.bm-cross {
  background: var(--bs-body-color);
  width: 2px !important;
  height: 20px !important;
}

.usrprofile {
  svg {
    font-size: 44px;
    color: rgba(36, 175, 101, 1);
  }
}