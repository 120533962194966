.js-dropdown {
  position: relative;
  display: inline-block;
}

.js-dropdown-button {
  padding: 10px 20px;
  background-color: #fff;
  cursor: pointer;
}

.js-dropdown-menu{
  position: absolute;
  top: 100%;
  left: inherit;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 150px;
  right: 0;
  text-align:left;
}

.js-dropdown-item{
  padding:10px 16px;
  cursor: pointer;
}

.js-dropdown-item1:hover {
  background-color: #f0f0f0;
}